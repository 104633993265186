.hp-bg-sp {
  /* background-image: url("../assets/images/login_bg.png"); */
  width: 100%;
  height: 100vh;
  /* background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat; */
}
.login-page-info {
  width: 50%;
  height: inherit;
  background-color: #f7f7ff;
  position: relative;
}
.login-page-logo-sec {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 80%;
}
.login-page-video-sec {
  width: 50%;
  background: linear-gradient(180deg,rgb(69 18 132 / 58%) 50.26%,rgba(42,3,76,.6) 100%);
}
.wel-msg-heading {
  /* font-family: "Montserrat-Bold"; */
  font-weight: 700;
  font-size: 3rem;
  line-height: 3.125rem;
  letter-spacing: -0.3px;
  color: #ffffff;
  text-transform: uppercase;
}
.wel-msg-heading span {
  color: #00ca9f;
}
.wel-msg-sub-heading {
  /* font-family: "Montserrat"; */
  font-style: normal;
  font-weight: 400;
  font-size: 2.688rem;
  line-height: 3.125rem;
  letter-spacing: -0.3px;
  color: #ffffff;
  margin-top: 40px;
}
.wel-text {
  color: #451284;
  font-family: Montserrat;
  font-size: 52px;
  font-style: normal;
  font-weight: 400;
  line-height: 65px;
  margin-top: 30px;
}
.find-more-hp-btn {
  padding: 15px 30px 15px 20px;
  height: 48px;
  background: #00ca9f;
  border: 1px solid #00ca9f;
  border-radius: 50px;
  color: #2a034c;
  /* font-family: "Calibri"; */
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  margin-top: 25px;
}
.login-page-wrapper {
  background-color: #2d285c;
  width: 100%;
  height: 100vh;
}

.login-box {
  width: 415px;
  padding: 30px 40px;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  box-shadow: 1px 1px 1px #efeff5;
  border-radius: 4px;
}

.box-ttl {
  font-style: normal;
  font-weight: 600;
  font-size: 26.832px;
  line-height: 43px;
  text-align: center;
  color: #212338;
}
.login-form-label {
  font-style: normal;
  font-weight: 400;
  font-size: 13.224px;
  line-height: 21px;
  color: #6b6d7c;
  margin-bottom: 5px;
}
.login-form-control {
  width: 100%;
  height: 36px;
  background: #ffffff;
  border: 1px solid #e2e2e9;
  border-radius: 5px;
  padding-left: 10px;
}
.container-checkbox-login {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  color: #222222;
  /* font-family: "Montserrat-SemiBold"; */
  font-size: 12px;
  line-height: 20px;
}
.container-checkbox-login:hover {
  color: #2d285c;
}
.container-checkbox-login input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  z-index: 9;
}
.container-checkbox-login .checkmark-login {
  position: absolute;
  top: 0;
  left: 0;
  height: 19px;
  width: 19px;
  background-color: #fff;
  border: 1px solid #2d285c;
  border-radius: 2px;
}
.container-checkbox-login:hover input ~ .checkmark-login {
  background-color: #2d285c;
}
.container-checkbox-login input:checked ~ .checkmark-login {
  background-color: #2d285c;
}
.container-checkbox-login .checkmark-login:after {
  content: "";
  position: absolute;
  display: none;
}
.container-checkbox-login input:checked ~ .checkmark-login:after {
  display: block;
}
.container-checkbox-login .checkmark-login:after {
  left: 6px;
  top: 2px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.pwd-eye-icon {
  right: 5px;
  top: 6px;
  fill: #94a3b8 !important;
  cursor: pointer;
}
.forgot-pwd-text {
  /* font-family: "Montserrat-Medium"; */
  font-size: 12px;
  line-height: 18px;
  color: #2d285c;
}
.sign-in-btn-lg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  background: #2d285c;
  border: 1px solid #2d285c;
  border-radius: 38px;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 17px;
  color: #ffffff;
  cursor: pointer;
  /* font-family: "Montserrat-Bold"; */
}
.sign-in-btn-lg:hover {
  background: #300c5c;
  border-color: #300c5c;
  transition: 0.3s;
}
.sign-in-btn-lg:disabled {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
}
.hr-line-sign-in {
  margin-top: 30px;
  margin-bottom: 30px;
  border: 1px solid #0f172a;
}
.google-sign-in-btn {
  border-radius: 38px;
  border: 1px solid #2d285c;
  background: #2d285c;
  display: flex;
  padding: 15px 20px;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 50px;
  width: 330px;
  transition: all 0.3s ease-out;
}
.google-sign-in-btn:hover {
  transform: translate(0px, -15px);
  transition: all 0.4s ease-out;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
}
.login-form-control:focus-visible {
  border: 2px solid #e2e2e9;
  outline: none;
}
.btn-disabled {
  cursor: not-allowed;
  background: #cbd5e1;
  border: 1px solid #cbd5e1;
  opacity: 0.5;
}
.login-icon-mb-sp {
  margin-bottom: 30px;
}
.rem-checkbox-wd {
  width: 100%;
  left: 0;
  height: 26px;
  top: -3px;
}
.login-text-wd {
  width: 75%;
}
.login-box-logo-wd {
  height: 60px;
}
.navigator-ai-logo {
  width: 330px;
}
.color-red {
  color: red;
}
.login-box-welcome-ttl {
  color: #2d285c;
  text-align: center;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: 0.3px;
}
.welcome-ttl-sub-text {
  color: #2d285c;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.3px;
}
.videoTag {
  object-fit: cover;
  height: 100vh;
  width: 50vw;
  position: fixed;
  top: 0;
  left: 50%;
  z-index: -1;
}
