.header {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 0px 35px;
}
.header-company-icon {
  padding: 10px 0 0 17px;
}
.company_logo {
  height: 55.9px;
  width: 171.89px;
}
.head-right-icons {
  display: flex;
  align-items: center;
}
.head-right-icons .icon {
  padding-right: 22px;
}
.person-text-detail {
  padding-right: 10px;
}
.person-details img {
  padding-right: 10px !important;
}
.person-details {
  display: flex;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.person-text-detail :first-child {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #414042;
  margin-bottom: 0.2rem;
}
.person-text-detail :last-child {
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #90a0b7;
  margin-bottom: 0;
}
.menu-item {
  display: flex;
}
.menu-item p {
  color: #2a034c;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 0px;
  padding-left: 20px;
  padding-right: 20px;
}
.menu-item a {
  text-decoration: none;
}
.menu-item a:hover p{
  color: #00CA9F;
  cursor: pointer;
}
.scroll-up-arrow {
  position: fixed;
  right: 30px;
  bottom: 30px;
  cursor: pointer;
}
.up-arrow-bg:hover {
  box-shadow: 0px 0px 16px #0000007d;
}
.up-arrow-bg {
  background-color: #00CA9F;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}
.cursor-pointer {
  cursor: pointer;
}