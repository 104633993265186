.header {
  display: flex;
  align-self: center;
  justify-content: space-between;
  background-color: #ffffff;
  width: 100%;
}
.header-company-icon {
  padding: 10px 0 0 17px;
}
.company_logo {
  height: 55.9px;
  width: 171.89px;
}
.head-right-icons {
  display: flex;
  align-items: center;
  margin-right: 29px;
}
.head-right-icons .icon {
  margin-right: 22px;
}
.person-text-detail {
  padding-right: 10px;
}
.person-details img {
  padding-right: 10px !important;
}
.person-details {
  display: flex;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.person-text-detail :first-child {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #414042;
  margin-bottom: 0.2rem;
}
.person-text-detail :last-child {
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
  color: #90a0b7;
  margin-bottom: 0;
}
