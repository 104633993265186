.about-container {
  /* background-image: url("../assets/images/landingBack.png"); */
  height: 640px;
  overflow-x: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #f7f7ff;
  background-position: bottom;
}
/* @media (min-width: 1440px) and (max-width: 1600px){

}
@media (min-width: 1440px) and (max-width: 1600px){

}
@media (min-width: 1440px) and (max-width: 1600px){

}
@media (min-width: 1440px) and (max-width: 1600px){

}
@media (min-width: 1440px) and (max-width: 1600px){
  .about-container {
    height: calc(100vh - 56px);
  }
} */
.about-container .about-inner-container {
  flex-direction: column;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 640px;
}
.about-container .about-inner-container p {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 500px;
}
.primary-button {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #ffffff;
  background-color: #4f46e5;
  border-radius: 4px;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid #4f46e5;
  /* padding: 6px 10px;
   */
  width: 139px;
  height: 40px;
}
.primary-button:hover {
  background-color: #6960fc;
  border: 1px solid #6960fc;
}
.non-primary-button {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #4f46e5;
  background-color: #fff;
  border-radius: 4px;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid #fff;
  width: 139px;
  height: 40px;
}
.non-primary-button:hover {
  background-color: #6960fc;
  border: 1px solid #6960fc;
  color: #fff;
}

.button-container {
  display: flex;
  /* justify-content: space-between; */
}

.button-container button:first-child {
  margin-right: 1rem;
}
.about-right-image {
  height: 511px;
  width: 607px;
  position: absolute;
  bottom: 8px;
}
.card-container {
  background-image: url("../assets/images/our-solutions-bg.png");
  background-color: #f7f7ff;
  min-height: 490px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-position: left bottom;
  padding-bottom: 102px;
  background-repeat: no-repeat;
}
.card-box-center-align {
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
}
.card-container h2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.flex-center {
  justify-content: center;
}

.col-md-4 {
  width: 22% !important;
}

.card .items-center p {
  font-size: 12px;
}
.card-slider-section {
  background-image: url("../assets/images/new-update-bg.png");
  height: 310px;
  padding: 40px;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  background-color: #f6f6ff;
  background-position: left center;
}
.card-slider-section h2 {
  color: #2a034c;
  text-align: center;
  font-size: 40px;
  line-height: 44px;
  font-weight: 400;
  margin-bottom: 0px;
}

.card-slider-box {
  height: auto;
  transition: transform 0.6s;
}
.card-slider-box:hover {
  transform: translate(0px, -15px);
  transition: transform 0.6s;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
}
.slick-slide {
  padding: 30px 10px;
}
.slick-dots li.slick-active button:before {
  opacity: 1 !important;
  opacity: 1 !important;
  background-color: #4f46e5 !important;
}
.slick-dots li button:before {
  font-size: 6px;
  opacity: 0.25;
  color: transparent !important;
  /* border: 1px solid #e2e8f0 !important; */
  border: 1px solid #4f46e5;
  border-radius: 50% !important;
  width: 15px !important;
  height: 15px !important;
}
.slick-prev,
.slick-next {
  display: none !important;
}
.card-slider-sp {
  min-height: 104px;
  padding: 12px 30px;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
}
.card-slider-sp:hover:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -2px;
  border-radius: inherit;
  background: radial-gradient(
    circle,
    #8093ff,
    #00b6ff,
    #00d4ff,
    #00ecfe,
    #66ffe5
  );
}
.card-slider-text {
  color: #272833;
  text-align: left;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 0px;
}
.card-slider-date-text {
  color: #34b8a7;
  text-align: left;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 0px;
}
.nav-card {
  position: relative;
  height: 310px;
  margin-bottom: 30px;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.12);
  transition: transform 0.6s;
}
.nav-card:hover {
  cursor: pointer;
  box-shadow: 0px 2px 10px 0px rgba(99, 102, 241, 0.48);
  transform: translate(0px, -25px);
  transition: transform 0.6s;
}
.coming-soon-nav {
  position: relative;
  height: 310px;
  margin-bottom: 30px;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.12);
  opacity: 0.4;
  cursor: no-drop;
}
.coming-soon-icon-sec {
  width: 119px;
  height: 119px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-card-hgt-sp {
  height: inherit;
  padding: 20px 30px;
}
.nav-card-know-text {
  color: #34b8a7;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
}
.overlay-section {
  display: block;
  width: 100%;
  height: 100%;
  padding: 30px 25px;
  border-radius: 20px;
  background: #fff;
}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
  transition: 0.5s ease;
  margin-bottom: 30px;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.12);
}
.nav-card:hover .overlay {
  opacity: 0;
}
.overlay-icon-wd {
  width: 82px;
}
.overlay-pra {
  color: #000;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  margin-top: 15px;
}
.navcard-icon-text-sp {
  margin-top: 25px;
}
h4 .overlay-text-ttl {
  font-family: Montserrat;
  font-style: normal;
  font-size: 28px;
  line-height: 20px;
}
.overlay-text-ttl-dark {
  color: #2a034c;
  font-weight: 500;
}
.overlay-text-ttl-blue {
  color: #34b8a7;
  font-weight: 800;
}
.navcard-inizio-logo-text {
  color: #2a034c;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px;
  margin-bottom: 0;
  text-align: right;
}
.overlay-text {
  color: #000000;
  font-size: 14px;
  line-height: 18px;
  text-align: justify;
}
.overlay-right-top-arrow {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #34b8a7;
  position: absolute;
  right: 10px;
  bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.email-query-container {
  background-image: url("../assets/images/help-corner-bg.png");
  padding: 40px 90px;
  background-size: cover;
  background-position: bottom right;
  background-repeat: no-repeat;
}
.email-sec {
  display: flex;
  background-color: #f1f1f5;
  border-radius: 6px;
  padding: 30px 45px;
}
.email-qus-img-wd {
  width: 400px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.email-section {
  flex: 2;
}
.help-corner {
  text-align: left;
  color: #7949f0;
  font-family: Montserrat;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  position: relative;
}
.any-questions {
  font-family: Montserrat;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 55px;
  background: linear-gradient(225deg, #4a5568 0%, #27272e 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.ask-text {
  color: #6b7280;
  text-align: left;
  font-family: Montserrat;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}
.email-box {
  position: relative;
  margin-top: 20px;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.send-email-btn {
  display: inline-flex;
  padding: 10px 14px;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #4f46e5;
  width: auto;
  margin-top: 15px;
  cursor: pointer;
  transition: 0.2s;
}
.send-email-btn:hover {
  background-color: #451284;
  transition: 0.3s;
}
.send-email-btn p {
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 0px;
}
.text-area-sp {
  height: 220px;
  width: 100%;
  border: 1px solid #cecece;
  border-radius: 6px;
  padding: 10px;
  resize: none;
}
:focus-visible {
  outline: none;
}
.send-icon-pos {
  position: absolute;
  right: 15px;
  bottom: 15px;
}
.wc-banner-img-section {
  background-image: url("../assets/images/wc-img.png");
  background-size: cover;
  width: 500px;
  height: 500px;
  border-radius: 50%;
  position: relative;
}
.hp-banner-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}
.wc-ttl {
  color: #333;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 3px;
  margin-bottom: 10px;
}
.wc-data-text {
  color: #333;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 200%;
  margin-top: 30px;
}
.wc-data-text span {
  color: #34b8a7;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 200%;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fade-in {
  opacity: 0;
  -webkit-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-duration: 1.4s;
  animation-duration: 1.4s;
}

.fade-in.delayed {
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}
.hgt-inherit {
  height: inherit;
}

.wc-mg-text {
  color: #451284;
  font-family: Montserrat;
  font-size: 52px;
  font-style: normal;
  font-weight: 400;
  line-height: 65px;
  transform: scale(0.94);
  animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
}
@keyframes scale {
  100% {
    transform: scale(1);
  }
}

.wc-mg-text span {
  display: block;
  opacity: 0;
  filter: blur(4px);
}

span:nth-child(1) {
  animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(2) {
  animation: fade-in 0.8s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(3) {
  animation: fade-in 0.8s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(4) {
  animation: fade-in 0.8s 0.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(5) {
  animation: fade-in 0.8s 0.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(6) {
  animation: fade-in 0.8s 0.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(7) {
  animation: fade-in 0.8s 0.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(8) {
  animation: fade-in 0.8s 0.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(9) {
  animation: fade-in 0.8s 0.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(10) {
  animation: fade-in 0.8s 1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(11) {
  animation: fade-in 0.8s 1.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(12) {
  animation: fade-in 0.8s 1.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(13) {
  animation: fade-in 0.8s 1.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(14) {
  animation: fade-in 0.8s 1.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(15) {
  animation: fade-in 0.8s 1.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(16) {
  animation: fade-in 0.8s 1.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(17) {
  animation: fade-in 0.8s 1.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(18) {
  animation: fade-in 0.8s 1.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

@keyframes fade-in {
  100% {
    opacity: 1;
    filter: blur(0);
  }
}

.slider-thumb-up::before {
  position: absolute;
  content: "";
  right: 0%;
  top: 0%;
  width: 120px;
  height: 120px;
  background: rgba(121, 73, 240, 0.25);
  border-radius: 62% 47% 82% 35% / 45% 45% 80% 66%;
  will-change: border-radius, transform, opacity;
  animation: sliderShape 5s linear infinite;
  display: block;
  /* z-index: -1; */
  -webkit-animation: sliderShape 5s linear infinite;
}
.slider-thumb-down::before {
  position: absolute;
  content: "";
  left: 0%;
  bottom: 0%;
  width: 120px;
  height: 120px;
  background: rgba(0, 202, 159, 0.25);
  border-radius: 62% 47% 82% 35% / 45% 45% 80% 66%;
  will-change: border-radius, transform, opacity;
  animation: sliderShape 5s linear infinite;
  display: block;
  /* z-index: -1; */
  -webkit-animation: sliderShape 5s linear infinite;
}
@keyframes sliderShape {
  0%,
  100% {
    border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
    transform: translate3d(0, 0, 0) rotateZ(0.01deg);
  }
  34% {
    border-radius: 70% 30% 46% 54% / 30% 29% 71% 70%;
    transform: translate3d(0, 5px, 0) rotateZ(0.01deg);
  }
  50% {
    transform: translate3d(0, 0, 0) rotateZ(0.01deg);
  }
  67% {
    border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
    transform: translate3d(0, -3px, 0) rotateZ(0.01deg);
  }
}
.navcard-center-icon-sec {
  width: 119px;
  height: 119px;
  background: #f0e6f0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
