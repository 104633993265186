.w-screen {
    width: 100vw;
}
.bg-white\/80 {
  background-color: rgba(255, 255, 255, 0.8);
}

.z-10000 {
    z-index: 10000;
}

.h-screen {
  height: 100vh;
}
.flex {
  display: flex;
}
.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.absolute {
  position: absolute;
}

.fixed {
    position: fixed;
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}
