/* Tailwind CSS Classes  */

.h-10 {
  height: 40px;
}
.max-w-368px {
  max-width: 368px;
}
.border {
  border: 1px solid #e2e8f0;
}
.bg-white {
  background-color: #fff;
}
.gap-2 {
  gap: 8px;
}
.border-none {
  border: none;
}

.placeholder\:text-gray::placeholder {
  font-family: Montserrat;
  color: #94a3b8;
}

.w-full {
  width: 100%;
}

.max-w-screen {
  max-width: 100vw;
}

.h-30px {
  height: 30px;
}

.w-30px {
  width: 30px;
}

.w-80px {
  width: 80px;
}

.p-2\.5 {
  padding: 10px;
}

.items-center {
  align-items: center;
}

.rounded-full {
  border-radius: 100rem;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.h-screen {
  height: 100vh;
}

.max-h-screen {
  max-height: 100vh;
}

.max-h-492px {
  max-height: 492px;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-1 {
  flex: 1 1 0%;
}

.bg-background {
  --background: #e2e8f0;
  background-color: var(--background);
}

.bg-primary {
  --primary: #4f46e5;
  background-color: var(--primary) !important;
}

.text-primary {
  --primary: #4f46e5;
  color: var(--primary) !important;
}

.rounded {
  border-radius: 0.25rem /* 4px */ !important;
}

.px-2\.5 {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.min-h-screen {
  min-height: 100vh;
}

.h-full {
  height: 100%;
}

.gap-5 {
  gap: 1.25rem /* 20px */;
}

.gap-4 {
  gap: 1rem /* 16px */;
}

.gap-2\.5 {
  gap: 0.625rem;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.text-lg {
  font-size: 1.125rem /* 18px */;
  line-height: 1.75rem;
}

.leading-5 {
  line-height: 1.25rem;
}

.text-text {
  --text: #272833;
  color: var(--text);
}

.text-sm {
  font-size: 0.875rem /* 14px */;
  line-height: 1.25rem;
}

.pt-22px {
  padding-top: 22px;
}

.pl-29px {
  padding-left: 29px;
}

.pr-31px {
  padding-right: 31px;
}

.pb-42px {
  padding-bottom: 42px;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.main-model {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-modal {
  width: 594px;
  height: 298px;
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;

  color: #fff;
  font-size: 16px;
  font-weight: 600;
  background-color: #2c285c;
  border-radius: 4px 4px 0px 0px;
  height: 54px;
}

.modal-body {
  padding: 16px 24px;
  padding-bottom: 2px;
}

.modal-content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 16px;
  padding: 16px;
  width: 100%;
  height: 136px;
  background-color: #f1f5f9 !important;
  border-radius: 4px;
}

.modal-content > label {
  font-size: 16px;
  font-weight: 600;
  color: #272833;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  height: 78px;
  border: 0;
  padding: 0 24px;
}

.model-content__input {
  position: relative;
}

.model-content__message {
  position: absolute;
  bottom: 2px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.model-content__error {
  color: #f44336;
  font-size: 12px;
  font-family: Montserrat;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.15px;
  word-wrap: break-word;
}

.btn-primary {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 13px;
  border-radius: 4px;
  background-color: #4f46e5 !important;
  color: #ffffff;
  font-weight: 500;
  border: 1px solid #4f46e5;
}

.btn-primary-outline {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 13px;
  border-radius: 4px;
  background-color: #fff;
  color: #4f46e5;
  font-weight: 500;
  border: 1px solid #4f46e5;
}

.btn-primary-outline:hover {
  background-color: #4f46e51a;
  color: #4f46e5;
  border: 1px solid #4f46e5;
}

.btn-primary-outline:active {
  background-color: #4f46e51a;
  color: #4f46e5;
  border: 1px solid #4f46e5;
}

.bg-background-success {
  --background-success: #ddf0eb;
  background-color: var(--background-success);
}

.cursor-pointer {
  cursor: pointer;
}

.text-danger {
  color: #f44336;
}

.text-success {
  color: #439771;
}

.text-xs {
  font-size: 12px;
}

.font-medium {
  font-weight: 500;
}

.py-1px {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.border-danger {
  border: 1px solid #f44336;
}

.border-success {
  border: 1px solid #439771;
}

.rounded-full {
  border-radius: 1000rem;
}

::-webkit-scrollbar {
  background: #dedddd;
  box-shadow: -1px 0px 0px 0px #f0f0f0 inset, 1px 0px 0px 0px #e8e8e8 inset;
}

/* Track */
::-webkit-scrollbar-track {
  width: 12px;
  padding: 4px;
  background-color: #dedddd;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #11182729;
  border: 4px solid transparent;
  border-radius: 9px;
  background-clip: content-box;
}

.py-2\.5 {
  padding: 10px;
}

.inline-block {
  display: inline-block;
}

.max-w-130px {
  max-width: 130px;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.overflow-hidden {
  overflow: "hidden";
}

.restore-modal {
  width: 331px;
  height: 337px;
  background-color: #fff;
  border-radius: 4px;
  padding: 20px 15px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 15px;
}

.restore-close-model {
  position: absolute;
  top: -10px;
  right: -10px;
}

.restore-modal-footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.restore-btn {
  width: 100%;
  height: 38px;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.1px;
  word-wrap: break-word;
  border: 1px solid #4f46e5;
  background-color: #4f46e5;
  color: white;
  border-radius: 4px;
}

.restore-btn-outline {
  color: #4f46e5;
  background-color: white;
}

.restore-btn-disable {
  background-color: #4e46e5b4;
  border: 1px solid #4e46e5b4;
}

.restore-modal-heading {
  color: #272833;
  font-size: 20px;
  font-family: Montserrat;
  font-weight: 600;
  word-wrap: break-word;
  text-align: center;
}

.restore-modal-description {
  color: #64748b;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 400;
  line-height: 22px;
  word-wrap: break-word;
  margin-bottom: 0;
  text-align: center;
}

.highlight {
  color: #4f46e5;
}