.footer {
  width: 100%;
  background: #e2e2f2;
}
.footer-items {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
}
.footer-pd-sp {
  padding: 0px 40px 10px 20px;
}
.footer-logo{
  padding-top: 8px;
  padding-bottom: 0px;
}
.footer-items-text a{
  color: #2a034c;
  text-align: left;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  font-weight: 400;
  position: relative;
  text-decoration: none;
}
.item-bar {
  color: #2a034c;
  text-align: left;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 20px;
  font-weight: 400;
  position: relative;
}
.all-rights-reserved {
  color: #2a034c;
  text-align: right;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  font-weight: 400;
}
